<app-header></app-header>
<!-- <div class="hero-section section pt-0">
    <div class="container pt-5">
    <div class="container h-100 text-white">
        <p class="display-6 fw-bold text-center an-1" style="padding-top: 9em;" >Get In Touch With Us</p>
      </div>
    </div>
   
  </div> -->

  <div class="hero-section section pt-0">
    <div class="container pt-5">
    </div>
   
  </div>

  <div class="section container">
    <div class="row">
        <div class="col-sm-8 offset-lg-2">
            <h4 >Contact Us</h4>
            <div class="row"> 
              <div class="col-md-6">
                  <div class="row">
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Name</mat-label>
                              <input matInput placeholder="Name">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                          <mat-form-field appearance="outline">
                              <mat-label>Country Code</mat-label>
                              <input matInput placeholder="Contry Code">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                          <mat-form-field appearance="outline">
                              <mat-label>Phone </mat-label>
                              <input matInput placeholder="Phone">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Email</mat-label>
                              <input matInput placeholder="Email">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Subject</mat-label>
                              <input matInput placeholder="Subject">
                          </mat-form-field>
                      </div>
      
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Message</mat-label>
                              <textarea matInput placeholder="Message"></textarea>
                          </mat-form-field>
                      </div>
                      <div class="col-sm-12">
                          <button class="btn btn-success"> Send Message</button>
                      </div>
      
                  </div>
              </div>
      
              <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Head Office</h4>
                        <span>+254 730 889 000 / +254 733 623 260</span> <br>
                        <span>
                            Nairobi +254 736 500 863, Kampala 020 0999298 / +256 774082853
                        </span> <br>
                        <span>Email Us: info@mashpoa.com</span>

                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
   
    
  </div>
  <app-footer></app-footer>