<app-header></app-header>
<div class="hero-section section pt-0">
    <div class="container pt-5">
    </div>
   
  </div>

  <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Mash Poa</p>
        <p >
          Mash; a brand synonymous with affordable long distance travel in Kenya and a unique national network.
          Founded in 2002, Mash EastAfrica is one of the largest provider of Bus transportation, serving an extensive list across Kenya and EastAfrica with a modern, environmentally friendly fleet.
          It has become an icon, providing safe, enjoyable and affordable travel to nearly thousands of passengers each year.
          Our vision is to operate a disciplined company in the transport sector, leading the way to improvement for prompt and efficient service.
          At Mash EastAfrica, we have a variety of vehicles that give our customers a comfortable traveling experience. We will regularly monitor the needs of our passengers and take the review from them, according to that we offer a great atmosphere for them so they can enjoy their journey in great comfort and safe hands.
        </p>
       
      </div>
      
    </div>
  </div>
  <app-footer></app-footer>
  